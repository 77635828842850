<template>
  <!-- Notation - Variables calculées -->
  <div class="variable-calculated-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Variables calculées</h2>
      </Container>
    </div>

    <Section>
      <Container>
        <template v-if="calculatedVariables.length > 0">
          <template
            v-for="(variable, index) in calculatedVariables"
            :key="index"
          >
            <div class="grid">
              <div class="tmp-col">
                <Checkbox
                  :key="componentKey"
                  :id="index.toString()"
                  :modelValue="!!getSelectedCalculatedVariable(variable.idFormatted)"
                  @update:modelValue="selectCalculatedVariable(variable)"
                  :items="[{
                    label: `${variable?.calcul?.designation} ${variable?.cible ? `- ${variable?.cible?.valeur}` : ''}`,
                    value: true,
                  }]"
                />
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          Aucun élément dans cette liste.
        </template>
      </Container>
    </Section>
  </div>

  <div class="view-footer" v-if="calculatedVariables.length > 0">
    <div class="view-actions view-actions--left">
      <Btn text="Annuler" :to="{ name: 'protocolRatingCalendar', params: {id: $route.params.id} }" />
      <Btn @click="handleSubmit()" text="Enregistrer" color="primary" />
    </div>
  </div>
</template>

<script>

import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'VariableCalculatedSubview',

  components: {
    Checkbox,
    Container,
    Section,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  data() {
    return {
      fiche: [],
      calculatedVariables: [],
      form: {
        calculatedVariables: [],
      },
      modal: {
        parameter: false,
      },
      modalData: null,
      defaultParameter: [],
      componentKey: 0,
    }
  },

  mounted() {
    this.getCalculatedVariables()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    /**
     * Get notation sheet
     */
    getCalculatedVariables() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable_calculee`, {
          limit: 0,
        })
        .then((response) => {
          this.calculatedVariables = response.data

          this.calculatedVariables = response.data.sort(
            (a, b) => a.calcul.designation.localeCompare(b.calcul.designation),
          )

          this.calculatedVariables.forEach((cv) => {
            // eslint-disable-next-line
            cv.idFormatted = this.getIdFormatted(cv)

            if (cv.id !== null) {
              this.selectCalculatedVariable(cv)
            }
          })

          this.emitter.emit('close-loader')
        })
    },

    /**
     * Select variable in the list
     * @param variable
     * @returns {Promise<void>}
     */
    selectCalculatedVariable(variable) {
      const calculatedVariable = this.getSelectedCalculatedVariable(variable.idFormatted)
      if (calculatedVariable) {
        calculatedVariable.supprimer = !calculatedVariable.supprimer
      } else {
        this.form.calculatedVariables.push({
          idFormatted: variable.idFormatted,
          id: variable.id,
          calcul_id: variable.calcul.id,
          cible_id: variable?.cible?.id,
          supprimer: false,
        })
      }
    },

    handleSubmit() {
      this.emitter.emit('open-loader')
      // Already selected, and unselected, remove them
      if (this.form.calculatedVariables.filter((cv) => cv.supprimer).length > 0) {
        this.fetchService
          .delete(`protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable_calculee`,
            this.form.calculatedVariables.filter((cv) => cv.supprimer).map((cv) => ({
              id: cv.id,
            }))).then(() => {
            this.getCalculatedVariables()
          })
      }

      // Variable is different of null, remove and add them
      if (
        this.form.calculatedVariables.filter((cv) => cv.id === null && !cv.supprimer).length > 0
      ) {
        this.fetchService
          .post(`protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable_calculee`,
            this.form.calculatedVariables.filter((cv) => cv.id === null && !cv.supprimer)
              .map((cv) => ({
                calcul_id: cv.calcul_id,
                cible_id: cv.cible_id,
                parametres: cv.parametres,
              })))
          .then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Les variables calculées ont bien été ajoutées.',
            })
            this.getCalculatedVariables()
          })
      }

      if (
        this.form.calculatedVariables.filter((cv) => cv.id !== null && !cv.supprimer).length > 0
      ) {
        this.fetchService
          .put(`protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable_calculee`,
            this.form.calculatedVariables.filter((cv) => cv.id !== null && !cv.supprimer)
              .map((cv) => ({
                id: cv.id,
                calcul_id: cv.calcul_id,
                cible_id: cv.cible_id,
              })))
          .then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Les variables calculées ont bien été modifiées.',
            })
            this.getCalculatedVariables()
          })
      }
      this.form.calculatedVariables = []
      this.emitter.emit('close-loader')
    },

    /** ***********************************************
     * Helpers
     ********************************************** */
    /**
     * Find in variables from api
     * @returns {*}
     * @param idFormatted
     */
    getCalculatedVariable(idFormatted) {
      return this.calculatedVariables.find((cfv) => cfv.idFormatted === idFormatted)
    },
    /**
     * Find in selected variables
     * @returns {*}
     * @param idFormatted
     */
    getSelectedCalculatedVariable(idFormatted) {
      return this.form.calculatedVariables.find((cfv) => cfv.idFormatted === idFormatted)
    },
    /**
     * Get the id formatted
     * @param variable
     * @returns {string|*}
     */
    getIdFormatted(variable) {
      if (variable.cible) {
        return `${variable.calcul.id}`
      }
      if (variable.id !== null) {
        return `${variable.calcul.id}-${variable.id}`
      }
      return variable.calcul.id
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
